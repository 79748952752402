import React, { Suspense, lazy } from "react";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary.jsx";
import LoaderSkeleton from "../../../Molecules/Common/LoaderSkeleton.jsx";
import { contentTypes, externalTypes } from "./layout.Helper.js";
import RangeMeeting from "../MeetingSync/RangeMeeting.jsx";
import M_ManagementApprovalFlow from './../../../Organisms/Common/ConsentRequest/M_ManagementApprovalFlow';
const M_NewsCard = lazy(() =>
  import("../../../Molecules/Common/Renderers/Views/NewsCards/M_NewsCard.jsx")
);
const M_ChartCard = lazy(() =>
  import("../../../Molecules/Common/Renderers/Views/Charts/M_ChartCard.jsx")
);
const M_MapCard = lazy(() =>
  import("../../../Molecules/Common/Renderers/Views/MapCards/M_MapCard.jsx")
);
const ViewFields = lazy(() => import("../Fields/ViewFields.jsx"));
const DisplaySections = lazy(() =>
  import("../DisplaySection/DisplaySection.jsx")
);
const DisplayTabs = lazy(() => import("../DisplayTabs/DisplayTabs.jsx"));
const M_ResultCard = lazy(() =>
  import(
    "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCard.jsx"
  )
);
const M_DropableFileUpload = lazy(() =>
  import("../../../Molecules/Common/File Upload/M_DropableFileUpload.jsx")
);
const PreviousTemplate = lazy(() =>
  import(
    "../../../Molecules/Common/Renderers/Views/PreviousTemplate/PreviousTemplate.jsx"
  )
);
const PreviousUploads = lazy(() =>
  import(
    "../../../Molecules/Common/Renderers/Views/PreviousTemplate/PreviousUploads.jsx"
  )
);
const O_ChartDataEntry = lazy(() =>
  import("../../../Organisms/Common/ChartData/O_ChartDataEntry.jsx")
);
const O_DealTimeline = lazy(() =>
  import("../../../Organisms/Common/DealTimeline/O_DealTimeline.jsx")
);
const O_Historychart = lazy(() =>
  import("../../../Organisms/Common/Historychart/O_Historychart.jsx")
);
const O_IRCDetails = lazy(() =>
  import("../../../Organisms/Common/IRCDetails/O_IRCDetails.jsx")
);
const O_MeetingSlotCard = lazy(() =>
  import("../../../Organisms/Common/MeetingSlotCard/O_MeetingSlotCard.jsx")
);
const Guidelines = lazy(() =>
  import("../../../Organisms/Common/Guidelines/Guidelines.jsx")
);
const ChecklistMaster = lazy(() => import("../Checklist/ChecklistMaster.jsx"));
const DealTeam = lazy(() => import("../DealTeam/DealTeam.jsx"));
const O_CheckList = lazy(() =>
  import("../../../Organisms/Common/Checklist/O_Checklist.jsx")
);
const MapGoogle = lazy(() => import("../../../Atoms/Map/A_GoogleMap.jsx"));
const O_activeFacility = lazy(() =>
  import("../../../Organisms/Common/ActiveFacility/O_activeFacility.jsx")
);
const O_LendersApproached = lazy(() =>
  import("../../../Organisms/Common/LendersApproached/O_lendersApproached.jsx")
);
const M_MeetingPreference = lazy(() =>
  import("../MeetingPreferences/M_MeetingPreference.jsx")
);
const Image = lazy(() => import("../../../Atoms/Image/A_Image.jsx"));
const ManageInvoices = lazy(() =>
  import("../../../Molecules/Common/PDFReader/ManageInvoices.jsx")
);
const M_ListDetails = lazy(() =>
  import("../../../Molecules/Common/DealAdministartion/M_ListDetails.jsx")
);
const SubmissionApproval = lazy(() =>
  import("../MonthlySubmission/submissionApproval.jsx")
);
const SubmissionReview = lazy(() =>
  import("../MonthlySubmission/submissionReview.jsx")
);
const PortfolioApproval = lazy(() =>
  import("../PortfolioManagement/PortfolioApproval.jsx")
);
const BudgetApproval = lazy(() =>
  import("../Budget&Approval/budgetApproval.jsx")
);
const ApproveAndReject = lazy(() =>
  import("../Budget&Approval/approveAndReject.jsx")
);
const M_PortfolioFileUpload = lazy(() =>
  import("../../../Molecules/Common/File Upload/M_PortfolioFileUpload.jsx")
);
const ImageGallery = lazy(() =>
  import("../../../Organisms/Common/ImageGallery/ImageGallery.jsx")
);
const Annoucment = lazy(() =>
  import("../../../Molecules/Announcment/Annoucement.jsx")
);
const GenericList = lazy(() =>
  import("../../../Organisms/Common/GenericList/GenericList.jsx")
);
const QuickLinks = lazy(() =>
  import("../../../Molecules/QuickLink/QuickLinks.jsx")
);
const ImageCard = lazy(() =>
  import("../../../Molecules/ImageCard/ImageCard.jsx")
);
const O_NewsPortal = lazy(() =>
  import("../../../Organisms/Common/NewsPortal/O_NewsPortal.jsx")
);
const M_PDFReader = lazy(() =>
  import("../../../Molecules/Common/PDFReader/M_PDFReader.jsx")
);
const O_InvestmentCase = lazy(() =>
  import("../../../Organisms/Common/InvestmentCase/O_InvestmentCase.jsx")
);
const ManualUpdate = lazy(() =>
  import(
    "../../../Molecules/Common/Renderers/Views/ManualUpdate/ManualUpdate.jsx"
  )
);
const IRReport = lazy(() =>
  import("../../../Organisms/Common/IRReport/IRReport.jsx")
);
const QuarterlyCodeMapping = lazy(() =>
  import(
    "../../../Organisms/Common/QuarterlyCodeMapping/QuarterlyCodeMapping.js"
  )
);
const QuarterlyKtTable = lazy(() =>
  import("../../../Organisms/Common/QuarterlyCodeMapping/QuartelyKtTable.js")
);
const O_GSTTabs = lazy(() =>
  import("../../../Organisms/Common/GST/O_GSTTabs.jsx")
);
const A_Tearsheet = lazy(() =>
  import("../../../Atoms/DataDisplay/Tearsheet/A_Tearsheet.jsx")
);
const ESGView = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_View")
);
const ESGSubmissionSummary = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESGSubmissionSummary")
);
const ESG_adminLayout = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_adminLayout")
);
const ESG_Report = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_Report")
);
const ESG_Submission = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_Submission")
);
const ESG_Approval = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_Approval")
);
const ESG_ExistingQuestions = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_ExistingQuestions")
);
const QuestionnaireScore = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/QuestionnaireScore")
);
const ESGRespondentActionPlan = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_RespondentActionPlan")
);
const MaturityMatrixPreviewTemplate = lazy(() =>
  import(
    "../../../Organisms/Common/ESG/screens/ESG_MaturityMatrixPreviewTemplate"
  )
);
const ESG_MaturityMatrixPreviewLegend = lazy(() =>
  import(
    "../../../Organisms/Common/ESG/screens/ESG_MaturityMatrixPreviewLegend"
  )
);
const ESG_MaturityMatrixDashBoardLegend = lazy(() =>
  import(
    "../../../Organisms/Common/ESG/screens/ESG_MaturityMatrixPreviewLegend.js"
  )
);
const ESGAdminCurrentPractice = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_AdminCurrentPractice")
);
const ESGAdminBestPractice = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_AdminBestPractice")
);
const ESGScoreSummary = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_ScoreSummary")
);
const MaturityMatrixLayout = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_MaturityMatrixLayout")
);
const ESG_Score = lazy(() =>
  import("../../../Organisms/Common/ESG/screens/ESG_Score")
);
const MasterMappingData = lazy(() =>
  import(
    "../../../Organisms/Common/QuarterlyCodeMapping/MasterMappingData/MasterMappingData.jsx"
  )
);
const KtCodesMain = lazy(() =>
  import(
    "../../../Organisms/Common/QuarterlyCodeMapping/KtCodesMain/KtCodesMain.jsx"
  )
);
const M_CustomView = lazy(() => import("./CustomCards/M_CustomView.jsx"));
const ImpactData = lazy(() => import("../ImpactData.jsx"));
const ProposalTracker = lazy(() =>
  import("../../../Organisms/Common/ProposalTracker/ProposalTracker.jsx")
);
const Comment = lazy(() =>
  import("../../../Atoms/Inputs/DropdownFields/Comment.jsx")
);
const M_MediaUpload = lazy(() =>
  import("../../../Organisms/Common/Summarix/M_MediaUpload.jsx")
);

export default function showContentConditional(
  contentType,
  content,
  item,
  chartFilter,
  parentThis
) {
  const { syncEdgeLabel } = item.header;
  const { toolbarSelections } = parentThis.props;
  switch (contentType) {
    case contentTypes.CRMDATA:
      if (content && content.tabs) {
        return (
          <ErrorBoundary>
            <Suspense fallback={<LoaderSkeleton />}>
              <DisplayTabs
                tabs={content.tabs}
                chartFilter={chartFilter}
                data={parentThis.props.data}
                CurrentCard={item}
              />
            </Suspense>
          </ErrorBoundary>
        );
      } else if (content && content.sections) {
        return (
          <ErrorBoundary>
            <Suspense fallback={<LoaderSkeleton />}>
              <DisplaySections sections={content.sections} />
            </Suspense>
          </ErrorBoundary>
        );
      } else if (content && content.fields) {
        return (
          <ErrorBoundary>
            <Suspense fallback={<LoaderSkeleton />}>
              <ViewFields
                fields={content.fields}
                syncEdgeLabel={syncEdgeLabel}
                content={content}
                CurrentCard={item}
                {...parentThis.props}
              />
            </Suspense>
          </ErrorBoundary>
        );
      }
      break;
    case contentTypes.SQLVIEW:
      if (toolbarSelections && toolbarSelections.filterLength > 0) {
        return (
          toolbarSelections.filters &&
          Array.isArray(toolbarSelections.filters) &&
          toolbarSelections.filterLength === toolbarSelections.filters.length &&
          toolbarSelections.filters.length > 0 && (
            <ErrorBoundary>
              <Suspense fallback={<LoaderSkeleton />}>
                <M_CustomView
                  tabs={content.tabs}
                  chartFilter={chartFilter}
                  data={parentThis.props.data}
                  CurrentCard={item}
                  sections={content.sections}
                  fields={content.fields}
                  syncEdgeLabel={syncEdgeLabel}
                  content={content}
                  toolbarSelectionsFilter={{ ...toolbarSelections }}
                  spName={
                    item && item.content && item.content.spName
                      ? item.content.spName
                      : ""
                  }
                  {...parentThis.props}
                />
              </Suspense>
            </ErrorBoundary>
          )
        );
      } else {
        return (
          <ErrorBoundary>
            <Suspense fallback={<LoaderSkeleton />}>
              <M_CustomView
                tabs={content.tabs}
                chartFilter={chartFilter}
                data={parentThis.props.data}
                CurrentCard={item}
                sections={content.sections}
                fields={content.fields}
                syncEdgeLabel={syncEdgeLabel}
                content={content}
                toolbarSelectionsFilter={""}
                spName={
                  item && item.content && item.content.spName
                    ? item.content.spName
                    : ""
                }
                {...parentThis.props}
              />
            </Suspense>
          </ErrorBoundary>
        );
      }
    case contentTypes.CHART:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <M_ChartCard
              Height={!item.minHeight ? "300px" : `${item.minHeight}px`}
              {...parentThis.props}
              chartFilter={chartFilter}
              content={
                content && content.chart
                  ? {
                      ...content.chart,
                      chartId: content.chart.id,
                      id: undefined,
                    }
                  : {}
              }
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.MAPS:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <M_MapCard content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.MANUAL_UPDATE:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ManualUpdate content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.IR_REPORT:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <IRReport content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.NEWS:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <M_NewsCard content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.RESULT_TEMPLATE:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <M_ResultCard
              content={content}
              stateObj={parentThis.state}
              item={item}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.DOCUMENTS:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <M_DropableFileUpload
              content={content}
              currentPage={parentThis.props.currentPage}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.PORTFOLIO_FILE_UPLOAD:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <M_PortfolioFileUpload
              content={content}
              currentPage={parentThis.props.currentPage}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.PREVIOUS_TEMPLATE:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <PreviousTemplate content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.PREVIOUS_UPLOADS:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <PreviousUploads content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.CHART_ENTRY:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_ChartDataEntry chart={content.chart} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.TIMELINE:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_DealTimeline content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.GUIDELINES:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <Guidelines content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.MAPGOOGLE:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <MapGoogle content={content} data={parentThis.props.data} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.IRC_DETAIL:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_Historychart
              maxHeight={content.maxHeight}
              content={content}
              callBack={parentThis.handleRecordID}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.DEAL_ADMINISTRATION:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_IRCDetails
              content={content}
              callBack={parentThis.handleRecordID}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.BOOKING_SLOT:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_MeetingSlotCard
              maxHeight={content.maxHeight}
              content={content}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.IC_VIEW:
      return (
        <Suspense fallback={<LoaderSkeleton />}>
          <M_ListDetails
            type={externalTypes.IRC}
            id={parentThis.state.recordId}
            resPayload={content && content.resPayload ? content.resPayload : ""}
            config={content && content.ircTabs ? content.ircTabs : []}
            content={content}
          />
        </Suspense>
      );
    case contentTypes.GENERIC_RESULT_TEMPLATE:
      return (
        <Suspense fallback={<LoaderSkeleton />}>
          <GenericList content={content}></GenericList>
        </Suspense>
      );
    case contentTypes.CHECKLIST_MASTER:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ChecklistMaster
              content={content}
              data={
                parentThis.props.data && parentThis.props.data.checklistResponse
                  ? parentThis.props.data.checklistResponse
                  : null
              }
              percentComplete={
                parentThis.props.data &&
                parentThis.props.data.checklistStatistics
                  ? parentThis.props.data.checklistStatistics
                  : null
              }
              dealData={parentThis.props.data ? parentThis.props.data : null}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ACTIVE_FACILITY:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_activeFacility />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.IMPACT_DATA:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ImpactData content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.LENDERS_APPROACHED:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_LendersApproached
              {...parentThis.props}
              label={
                content && content.content && content.content.label
                  ? content.content.label
                  : null
              }
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.MANAGED_BY:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <DealTeam
              content={content}
              currentPage={parentThis.props.currentPage}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.CHECKLIST:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_CheckList ircID={parentThis.state.recordId} config={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.MANAGE_INVOICES:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ManageInvoices content={content} data={parentThis.props.data} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.MEETING___PREFERENCE:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <M_MeetingPreference content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.IMAGE:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <Image content={content} {...parentThis.props}></Image>
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.TEARSHEET:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <A_Tearsheet></A_Tearsheet>
          </Suspense>
        </ErrorBoundary>
      );
    // case contentTypes.DEALSEARCHBAR:
    //   return <SearchBar  />
    case contentTypes.BUDGET_APPROVAL:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <BudgetApproval
              content={content}
              getCurrentPage={parentThis.props.getCurrentPage}
              data={parentThis.props}
              onChangeNextApprover={parentThis.onChangeNextApprover}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.SUBMISSION_REVIEW:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <SubmissionReview
              content={content}
              getCurrentPage={parentThis.props.getCurrentPage}
              approvalData={parentThis.state.approversubmission}
              fundaccountantstatus={parentThis.state.submissionVal}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.SUBMISSION_APPROVAL:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <SubmissionApproval
              content={content}
              getCurrentPage={parentThis.props.getCurrentPage}
              data={parentThis.props}
              onChangeSubmission={parentThis.onChangeSubmission}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.PORTFOLIOAPPROVAL:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <PortfolioApproval
              content={content}
              getCurrentPage={parentThis.props.getCurrentPage}
              approvalData={parentThis.state.approversubmission}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.APPROVE_AND_REJECT:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ApproveAndReject
              content={content}
              getCurrentPage={parentThis.props.getCurrentPage}
              approvalData={parentThis.state.approverstage}
              fundaccountantstatus={parentThis.state.fundaccountantVal}
            />
          </Suspense>
        </ErrorBoundary>
      );
    // Will used later As per discussion
    // case contentTypes.EXTERNAL_COMMITTEE:
    //   return (
    //     <ErrorBoundary>
    //       <ExternalCommittee content={content}/>
    //     </ErrorBoundary>
    //   );
    case contentTypes.IMAGE_GALLERY:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ImageGallery content={content} {...parentThis.props} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ANNOUCMENT:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <Annoucment content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.QUICK_LINKS:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <QuickLinks content={content} {...parentThis.props} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.IMAGE_CARD:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ImageCard content={content} {...parentThis.props} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.NEWS_PORTAL:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_NewsPortal content={content} {...parentThis.props} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.STATIC_PDF:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <M_PDFReader
              pdf={content.filename ? content.filename : ""}
              showDefaultMessage={true}
              container={content.type ? content.type : externalTypes.GENERIC}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.INVESTMENT_CASE:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_InvestmentCase content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.QUARTERLY_KT_CODE_MAPPING:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <QuarterlyKtTable
              updateEditData={parentThis.updateEditData}
              startEditing={parentThis.startEditing}
              setSelectedTab={parentThis.updateSelectedTab}
              newSavedData={parentThis.state.newSavedData}
              updateSavedData={parentThis.updateSavedData}
              QuarterCode={parentThis.state.QuarterCode}
              isEditing={parentThis.state.isEditing}
              chartFilter={chartFilter}
              content={content}
              {...parentThis.props}
            />
          </Suspense>
          <Suspense fallback={<LoaderSkeleton />}>
            <QuarterlyCodeMapping
              editData={parentThis.state.editData}
              isEditing={parentThis.state.isEditing}
              selectedTab={parentThis.state.selectedTab}
              newSavedData={parentThis.state.newSavedData}
              updateSavedData={parentThis.updateSavedData}
              updateQuarterCode={parentThis.updateQuarterCode}
              endEditing={parentThis.endEditing}
              chartFilter={chartFilter}
              content={content}
            />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.GST:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <O_GSTTabs content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESG:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESG_adminLayout content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESGUserView:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESGView content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESGExistingQuestion:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESG_ExistingQuestions />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESGSubmissionSummary:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESGSubmissionSummary content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESGReport:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESG_Report />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESGRespondentActionPlan:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESGRespondentActionPlan content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.MaturityMatrixPreviewTemplate:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <MaturityMatrixPreviewTemplate content={content} />
          </Suspense>
        </ErrorBoundary>
      );

    case contentTypes.ESG_MaturityMatrixPreviewLegend:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESG_MaturityMatrixPreviewLegend content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESG_MaturityMatrixDashBoardLegend:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESG_MaturityMatrixDashBoardLegend content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESGScore:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESG_Score content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESGSubmission:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESG_Submission />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESGApproval:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESG_Approval />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.QuestionnaireScore:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <QuestionnaireScore content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.MASTERMAPPINGDATA:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <MasterMappingData content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.QUARTERLY_KT_CODE:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <KtCodesMain content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESGAdminCurrentPractice:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESGAdminCurrentPractice content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.ESGAdminBestPractice:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESGAdminBestPractice content={content} />
          </Suspense>
        </ErrorBoundary>
      );

    case contentTypes.ESGScoreSummary:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ESGScoreSummary content={content} />
          </Suspense>
        </ErrorBoundary>
      );

    case contentTypes.ESGMaturityMatrixLayout:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <MaturityMatrixLayout content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.PROPOSAL_TRACKER:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <ProposalTracker content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.COMMENT:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <Comment content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.SUMMARIX:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <M_MediaUpload content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.RANGE_MEETING:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <RangeMeeting />
          </Suspense>
        </ErrorBoundary>
      );
    case contentTypes.MANAGEMENT_APPROVAL_FLOW:
      return (
        <ErrorBoundary>
          <Suspense fallback={<LoaderSkeleton />}>
            <M_ManagementApprovalFlow content={content} />
          </Suspense>
        </ErrorBoundary>
      );
    default:
      return "";
  }
}
