/* eslint-disable */
import React, { useEffect, useState } from "react";
import A_DatePicker from "../../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import LoaderSkeleton from "../../../Molecules/Common/LoaderSkeleton";
import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/core/styles";
import { httpPost } from "../../../../../Utils/apis/apis";
import { renderIcons } from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCardHelper";
import { epochToDateFormatHelper } from "../../../../../Utils/Helpers/dateUtils";

const useStyles = makeStyles(() => ({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
    // marginTop: "10px",
    padding: "2rem",
  },
}));
const RangeMeeting = (props) => {
  const classes = useStyles();
  let initialStartDate = new Date();
  initialStartDate.setDate(initialStartDate.getDate() - 3);
  initialStartDate = new Date(initialStartDate);
  initialStartDate.setHours(0, 0, 0, 0);
  initialStartDate = Math.floor(initialStartDate.getTime() / 1000);

  let initialEndDate = new Date();
  initialEndDate.setDate(initialEndDate.getDate() + 2);
  initialEndDate = new Date(initialEndDate);
  initialEndDate.setHours(23, 59, 59, 999);
  initialEndDate = Math.floor(initialEndDate.getTime() / 1000);

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [isLoading, setIsLoading] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [error, setError] = useState(""); // For validation error
  const id = JSON.parse(localStorage.getItem("contact_crm")).id;

  // API call function
  const genericGremlinSP = async () => {
    const url = `/GenericGremlin/generic/getMeetingsWithStartandEnddDate`;
    const requestPayload = {
      "@id": id,
      "@startDT": startDate,
      "@endDT": endDate,
    };
    const result = await httpPost(url, requestPayload);
    const filtered =
      result &&
      result.filter(
        (item) =>
          item.isOutlook === item.isTagged || // Condition 1
          (item.isOutlook && item.TaggedByEdge) || // Condition 2
          (item.isOutlook && !item.UserPref && !item.TaggedByEdge) // Condition 3
      );

    return filtered;
  };

  // Fetch data when both startDate and endDate are valid and available
  useEffect(() => {
    if (startDate && endDate && !error) {
      const fetchData = async () => {
        setIsLoading(true);
        const data = await genericGremlinSP();
        setResultData(data);
        setIsLoading(false);
      };
      fetchData();
    }
  }, [startDate, endDate, error]);

  // Handle start date change
  const handleStartDate = (e) => {
    let newStartDate = new Date(e * 1000);
    newStartDate.setHours(0, 0, 0, 0);
    newStartDate = Math.floor(newStartDate.getTime() / 1000);
    setStartDate(newStartDate);

    if (newStartDate > endDate) {
      setError("Start date cannot be later than end date*");
    } else {
      setError("");
    }
  };

  // Handle end date change
  const handleEndDate = (e) => {
    let newEndDate = new Date(e * 1000);
    newEndDate.setHours(23, 59, 59, 999);
    newEndDate = Math.floor(newEndDate.getTime() / 1000);
    setEndDate(newEndDate);
    if (startDate > newEndDate) {
      setError("Start date cannot be later than end date*");
    } else {
      setError("");
    }
  };

  return (
    <>
      <div
        style={{
          maxWidth: "20rem",
          display: "flex",
          marginTop: "0rem",
          marginLeft: "0.5rem",
          justifyContent: "space-between",
        }}
      >
        <A_DatePicker
          defaultValue={startDate}
          onChange={handleStartDate}
          label="From"
        />
        <span style={{ marginLeft: "2rem" }}>
          <A_DatePicker
            onChange={handleEndDate}
            defaultValue={endDate}
            label="To"
          />
        </span>
      </div>

      {error && (
        <div
          style={{ color: "red", marginLeft: "0.5rem", marginTop: "0.2rem" }}
        >
          {error}
        </div>
      )}

      {!isLoading ? (
        <>
          {resultData && resultData.length > 0 ? (
            <div
              style={{
                maxHeight: props.content.maxHeight,
                overflowY: props.content.overflowY,
              }}
            >
              <A_Divider />
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  marginLeft: "0.5rem",
                }}
              >
                {resultData.map((meeting) => (
                  <React.Fragment key={meeting.id}>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0.8rem",
                        marginTop: "0.8rem",
                      }}
                    >
                      <Avatar>{renderIcons("Meeting")}</Avatar>
                      <div style={{ marginLeft: "0.5rem" }}>
                        <a
                          href={`meeting/${meeting.id}`}
                          style={{
                            color: "#007bff",
                            textDecoration: "none",
                            fontSize: "18px",
                          }}
                        >
                          {meeting.Subject}
                        </a>
                        <br />
                        <span style={{ fontSize: "13px", color: "#777" }}>
                          <strong>Starts On: </strong>
                          {epochToDateFormatHelper(
                            meeting.StartDate,
                            "DD/MM/YYYY HH:mm"
                          )}
                        </span>
                        <span
                          style={{
                            fontSize: "13px",
                            color: "#777",
                            marginLeft: "20px",
                          }}
                        >
                          <strong>Ends On: </strong>
                          {meeting.EndDate > 0
                            ? epochToDateFormatHelper(
                                meeting.EndDate,
                                "DD/MM/YYYY HH:mm"
                              )
                            : "-"}
                        </span>
                      </div>
                    </li>
                    <A_Divider />
                  </React.Fragment>
                ))}
              </ul>
            </div>
          ) : (
            <span>
              <A_Divider />
              <div className={classes.noData}>
                <p>WE ARE SORRY, NO CONTENT FOUND!</p>
              </div>
            </span>
          )}
        </>
      ) : (
        <LoaderSkeleton />
      )}
    </>
  );
};

export default RangeMeeting;
