export const message = {
  ARE_YOU_SURE: "Are you sure you want to close?",
  COMMENT_ACTION_MSG:
    "Action captured successfully. Servicer AM will be notiified.",
  CONTACT_ADMIN: "Something went wrong. Try again or contact admin",
  CRM_FORM_UPDATE: "Record updated successfully!",
  CRM_FORM_UPDATE_ERROR: "Error in saving crm record!",
  DATA_NOT_FOUND: "No trail found.",
  DATA_VALIDATION: "Data Validation:",
  DELETE_CRM_RECORD: "Record deleted successfully!",
  DELETE_ROW: "Are you sure you want to delete this row?",
  DELETE_SUCCESS: "File successfully deleted",
  DELETE_TAB_WARNING: "Are you sure you want to delete this tab?",
  DELETE_WARNING: "Are you sure you want to delete?",
  DUPLICATE_RECORD_FOUND: "Duplicate Record found.",
  EDGE_DELETE_SUCCESS: "Edge successfully deleted",
  ESG_DD_FIRST_APPROVAL: "Approver Level 1 not assigned to esgsubmission",
  FILE_FORMAT_NOT_SUPPORTED: "File format not supported",
  FILE_UPLOAD_SUCESSFUL: "File successfully Uploaded",
  FILTER_SELECTION: "Please make selection of the filter(s)",
  GIFT_APPROVE_SUCESSFUL: "The gift has been approved successfully",
  GIFT_REJECT_SUCCESS: "The gift has been Rejected",
  INVOICE_APPROVE_REJECT: "The invoice has been Rejected",
  INVOICE_APPROVE_SUCCESSFUL: "The invoice has been approved successfully",
  MAIL_SENT_SUCCESS: "Mail Sent Successfully",
  MAXIMUM_LIMIT_REACHED: "Max Number limit reached",
  MEETING_DELETED: "Meeting successfully deleted",
  MEETING_SYNCED: "Meeting successfully synced",
  MULTIPLE_FILES_NOT_ALLOWED: "Multiple files are not allowed",
  NO_CONTENT: "WE ARE SORRY, NO CONTENT FOUND!",
  NO_DATA: "No Data Available",
  NO_DATA_AVAILABLE: "No data available.",
  NO_DATA_FOUND: "No Data Found",
  NO_EDGE_FOUND: "No edge found!",
  NO_IMAGE_FOUND: "WE ARE SORRY, NO IMAGE FOUND!",
  NO_MEETING_FOUND: "WE ARE SORRY, NO MEETING FOUND!",
  NO_SEARCH_FOUND: "No Match Found.",
  NOT_AUTHORIZED: "You are not authorized to approve this.",
  PLEASE_WAIT: "Please wait..",
  RECORD_DELETE_ERROR: "Record could not be deleted",
  RECORD_DELETE_SUCCESS: "Record successfully deleted",
  REPORT_NAME_ERROR: "Report name already exists",
  REQUIRED: "Required",
  REQUIRED_FIELD: "This field is required",
  SELECT_ACCOUNT: "Please Select an Account",
  SELECT_TAB_MESSAGE: "Please select at least one tab to download excel",
  SOMETHING_WENT_WRONG: "Something went wrong",
  SUCCESS: "Request successfully executed",
  SYNC_ERROR: "Slot syncing fail please connect to support",
  syncStart: "Syncing has been started, please wait it will take few minutes.",
  TRADE_APPROVE_SUCESSFUL: "The trade has been approved successfully",
  TRADE_REJECT_SUCCESS: "The trade has been Rejected",
  UN_AUTHORISED: "Request not authorized",
  VALID_VALUE: "Please enter valid value",
};
export const errorCode = {
  200: message.SUCCESS,
  401: message.UN_AUTHORISED,
  500: message.CONTACT_ADMIN,
};
