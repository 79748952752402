import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import TextField from "@material-ui/core/TextField";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { httpPost } from "../../../../../Utils/apis/apis";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { Box } from "@material-ui/core";
import { propTypes } from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { getCurrentPage } from "../../../../../store/actions/currentPageActions";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles(() => ({
  add: {
    width: "100%",
  },
  dangerButton: {
    backgroundColor: "#dc3545",
    color: "white",
    "&:hover": {
      backgroundColor: "#ba202e",
    },
  },
}));

const M_ManagementApprovalFlow = (props) => {
  const [comments, setcomments] = React.useState("");
  const [authorise, setAuthorise] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [status, setStatus] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [toolbar, setToolBar] = useState("");
  const handleChange = (event) => {
    handleValidation();
    setcomments(event.target.value);
  };
  const { pageUrl, crmId } = useParams();
  const user = useSelector((state) => state.oidc.user);
  // const userEmail = user && user.profile && user.profile.email;
  const userName = `${user && user.profile && user.profile.given_name} ${
    user && user.profile && user.profile.family_name
  }`;
  const [uploadedBy, setUploadedBy] = useState("");
  const userid = JSON.parse(localStorage.getItem("contact_crm")).id;

  const getApprovers = async () => {
    let payload = {
      spName: props.content.getApprover,
      parameters: {
        investmentId: crmId,
        sourceId: toolbar,
      },
    };
    let response = await httpPost(`/SQLBasedForm/SQLFormOperations`, payload);
    if (Object.values(response[0]).includes(userid)) {
      setAuthorise(true);
      setUploadedBy(response[0].Uploadedby);
    }
    setLoading(false);
  };
  // useEffect(() => {
  //   getApprovers();
  // }, []);

  const handleValidation = () => {
    if (!comments || comments.trim() === "") {
      setError(true);
      setHelperText("Comment is required");
      return false;
    }
    setError(false);
    setHelperText("");
    return true;
  };
  useEffect(() => {
    if (props.toolbarSelections.filters.length > 0) {
      setToolBar(props.toolbarSelections.filters[0].value);
      if (toolbar) {
        getApprovers();
      }
    }
  }, [props.toolbarSelections]);
  const handleBtnStatus = async (value) => {
    if (handleValidation()) {
      let dataObj = {
        comments: comments,
        source: toolbar,
        username: userid,
      };
      let payload = {
        spName:
          value == "Approved"
            ? props.content.approveSP
            : props.content.rejectSP,
        parameters: { ...dataObj },
      };

      await httpPost(`/SQLBasedForm/SQLFormOperations`, payload, "", true);
      if (value == "Rejected") {
        const currentDate = new Date();

        const futureDate = new Date(currentDate.getTime() + 1 * 60 * 1000);

        const triggerDateTime = futureDate.toISOString();
        let templateParam = {
          investmentName: props.currentPage.Name,
          name: userName,
          reason: comments,
        };
        templateParam = JSON.stringify(templateParam);
        let updatedTemplate = {
          ...props.content.email.template,
          templateParam,
        };

        let emailData = {
          ...props.content.email,
          triggerDateTime,
          template: updatedTemplate,
          recipients: uploadedBy,
        };
        await httpPost(`/Email/queue-email`, emailData);
      }
      setStatus(true);
      setTimeout(() => {
        setStatus(false);
        dispatch(getCurrentPage({ pageUrl, crmId }));
      }, 2000);
    }
  };
  const isButtonDisabled = toolbar && toolbar != "000" ? false : true;
  return (
    <>
      {!loading ? (
        authorise ? (
          <div>
            <InputLabel>
              <div className="">
                <TextField
                  id="standard-basic"
                  label="Comments:"
                  onChange={handleChange}
                  className={classes.add}
                  required
                  error={error}
                  helperText={helperText}
                  value={comments}
                  disabled={isButtonDisabled}
                />
              </div>
            </InputLabel>
            <div style={{ marginTop: 20 }}>
              <Button
                style={{
                  marginRight: 10,
                  backgroundColor: isButtonDisabled ? "" : "#66BB6A",
                  color: isButtonDisabled ? "" : "white",
                }}
                variant="contained"
                disabled={isButtonDisabled}
                onClick={() => handleBtnStatus("Approved")}
              >
                {props.content.approveButtonLabel}
              </Button>
              <Button
                style={{ marginRight: 10 }}
                className={classes.dangerButton}
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                onClick={() => handleBtnStatus("Rejected")}
              >
                {props.content.rejectButtonLabel}
              </Button>
            </div>
            <A_Snackbar
              open={status}
              message={props.content.snackBarMsg}
              autoHideDuration={2000}
              horizontal="center"
              type={"success"}
            />
          </div>
        ) : (
          <Alert severity="info">{props.content.notAuthoriseMessage}</Alert>
        )
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
M_ManagementApprovalFlow.propTypes = {
  content: propTypes.any,
  currentPage: propTypes.any,
  toolbarSelections: propTypes.any,
};
function mapStateToProps(state) {
  return {
    toolbarSelections: state.manageToolbarSelections,
    currentPage: state.currentPage.data,
  };
}
export default connect(mapStateToProps)(M_ManagementApprovalFlow);
